<template>
<div class="requisites-modal">
  <!-- header -->
  <div slot="title">
    <div class="d-flex">
      <div>
        <h5>{{ accountObj.name }} {{ $t('common.requisites') }}</h5>
      </div>
      <div class="ml-auto mr-5">
        <div class="font-size-18" style="margin-top: -0.6rem">
          <a-tooltip :title="`${$t('buttons.btnAdd')} ${$t('common.requisites')}`" placement="bottom">
            <a href="javascript:" @click="showAddRequisiteModal">
              <a-icon type="plus" class="text-primary"/>
            </a>
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
  <!-- header -->
  <!-- body -->
  <div>
    <a-tabs :default-active-key="activeTab" v-model="activeTab" :animated="false">
      <a-tab-pane key="buy" :tab="$t('common.buy')">
        <a-table
          :columns="requisitesColumns"
          :dataSource="requisites"
          :row-key="record => record.id"
          :pagination="pagination"
          :loading="requisitesLoading"
          @change="handleTableChange"
        >
          <template v-slot:psystem="psystem">
            <div>
              <span style="width: 32px" class="text-center mr-1"><img :src="psystem.img_path" width="18"/></span>
              <span>{{ psystem.name.length > 16 ? psystem.name.substr(0, 16) + '..' : psystem.name }}</span>
            </div>

          </template>
          <template v-slot:address="address, record">
            {{ address }}
            <span class="d-block text-gray-5">{{ record.name }}</span>
          </template>
          <template v-slot:max_amount="max_amount, record">
          <span>
            {{ max_amount }}
          </span>
            <span>
            <a-tooltip :title="record.currency.desc" placement="bottom">
              {{ record.currency.abbr }}
            </a-tooltip>
          </span>
          </template>
          <template v-slot:comment="comment">
          <span v-if="comment" class="font-size-12">
            {{ comment.substring(0, 24) }} <span v-if="comment.length > 24">...</span>
            <a-tooltip v-if="comment.length > 24"
                       :title="`${$t('buttons.btnClick')}${$t('pre.to')}${$t('buttons.btnOpen')}`" placement="left">
              <a href="javascript:;" class="text-primary font-size-14" @click="showComment(comment)"><a-icon
                type="eye"/></a>
            </a-tooltip>
          </span>
          </template>
          <template v-slot:actions="text, record">
            <div class="flex">
<!--              <span class="">-->
<!--                <a-tooltip v-if="record.as_any_bank" :title="`${$t('buttons.btnDefault')}`" placement="bottom">-->
<!--                  <span class="flex">-->
<!--                    <span class="small text-primary"></span> <a-radio class="ml-1" :checked="true"></a-radio>-->
<!--                  </span>-->
<!--                </a-tooltip>-->
<!--                <a-tooltip v-else :title="$t('tooltips.makeRequisitesDefaultForAnyBank')" placement="bottom">-->
<!--                  <a-radio class="ml-1" :checked="false" :reqId="record.id"-->
<!--                           @click="requisiteSetDefault(record)"></a-radio>-->
<!--                </a-tooltip>-->
<!--              </span>-->
              <span class="ml-2">
                <a-tooltip :title="$t('buttons.btnEdit')" placement="bottom">
                  <a href="javascript:" class="text-gray-6" @click="showEditRequisiteModal(record)"><a-icon type="edit"/></a>
                </a-tooltip>
              </span>
              <span class="ml-3">
                <a-popconfirm
                  :title="`${$t('buttons.btnDelete')} ${record.address}?`"
                  @confirm="deleteRequisite(record)"
                >
                  <a-tooltip :title="$t('buttons.btnDelete')" placement="bottom">
                    <a href="javascript:" class="text-danger"><a-icon type="delete"/></a>
                  </a-tooltip>
                </a-popconfirm>
              </span>
            </div>
          </template>
          <!--        <template v-slot:default="text, record">-->
          <!--          <a-switch v-model="record.as_any_bank"  />-->
          <!--        </template>-->
          <template v-slot:status="text, record">
          <span v-if="record.is_draft" class="text-gray-5">
            <a-tooltip :title="$t('common.archive')" placement="bottom">
              <a-icon type="cloud" class="font-size-18"/>
            </a-tooltip>
          </span>
            <a-switch v-else v-model="record.is_active" :reqId="record.id" @change="requisiteActiveState"/>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="sale" :tab="$t('common.sell')">
        <saleRequisitesTable ref="saleRequisitesTable" @openSaleRequisitePopup="showEditSaleRequisiteModal"
                             :accountObj="accountObj"/>
      </a-tab-pane>
      <a-tab-pane key="usdt" :tab="$t('common.usdt')">
        <USDTTabContent
          :accountObj="accountObj"
          ref="USDTTabContent"
          @openUSDTWalletModal="openUSDTWalletModal"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
  <!-- body -->
  <!--    <div v-if="requisitesLoading" class="text-center">-->
  <!--      <a-icon type="loading" class="text-primary font-size-60" />-->
  <!--    </div>-->
  <a-modal v-model="addRequisiteModal"
           :destroyOnClose="true"
           :title="requisiteObj ? `${$t('buttons.btnChange')} ${$t('common.requisites')} '${requisiteObj.psystem.name}' ${$t('pre.for')} ${accountObj.name}` : `${$t('buttons.btnChange')} ${$t('common.requisites')} ${$t('pre.for')} ${accountObj.name}`"
           :footer="null"
           class="requisite-modal"
           width="680px"
  >
    <template v-if="activeTab === 'buy'">
      <add-requisite-component
        :requisite-info="requisiteObj"
        :account-obj="accountObj"
        @closeModalFunction="closeRequisiteModal"
        @submitModalFunction="requisiteModalSubmit"/>
    </template>
    <template v-else-if="activeTab === 'usdt'">
      <USDTCreateModalContent
        :accountObj="accountObj"
        :wallet="USDTWallet"
        @created="closeRequisiteModal"
      />
    </template>
    <template v-else>
      <saleRequisiteModal :accountObj="accountObj" :requisite="saleRequisite"
                          @closeModalFunction="closeSaleRequisiteModal"/>
    </template>
  </a-modal>
  <a-modal v-model="commentModal"
           class="comment-modal"
           width="520px"
           :destroyOnClose="true"
           :closable="false"
  >
    <div>
      <span v-if="requisiteObj">{{ requisiteObj.comment }}</span>
    </div>
  </a-modal>
</div>
</template>
<script>
import ApiService from '@/services/api/api.service'
import addRequisiteComponent from '@/views/accounts/requisites/addRequisiteModal.vue'
import { Modal } from 'ant-design-vue'
import USDTTabContent from './tab-usdt/USDTTabContent.vue'
import USDTCreateModalContent from './tab-usdt/USDTModalContent.vue'
import saleRequisitesTable from './saleRequisitesTable.vue'
import saleRequisiteModal from './saleRequisiteModal.vue'

const requisitesColumns = [
  {
    title: 'Method',
    dataIndex: 'psystem',
    key: 'psystem',
    width: '20%',
    scopedSlots: { customRender: 'psystem' },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: '20%',
    scopedSlots: { customRender: 'address' },
  },
  // {
  //   title: 'Получатель',
  //   dataIndex: 'name',
  //   key: 'name',
  //   width: '15%',
  //   scopedSlots: { customRender: 'name' },
  // },
  {
    title: 'Limit',
    dataIndex: 'max_amount',
    key: 'max_amount',
    width: '12%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'max_amount' },
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    width: '24%',
    scopedSlots: { customRender: 'comment' },
  },
  {
    title: 'Action',
    dataIndex: 'actions',
    // sorter: (a, b) => a.amount - b.amount,
    align: 'right',
    width: '12%',
    scopedSlots: { customRender: 'actions' },
  },
  // {
  //   title: 'Основной',
  //   dataIndex: 'default',
  //   // sorter: (a, b) => a.amount - b.amount,
  //   align: 'center',
  //   scopedSlots: { customRender: 'default' },
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '12%',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'accountRequisites',
  props: ['accountObj'],
  components: {
    saleRequisitesTable,
    addRequisiteComponent,
    saleRequisiteModal,
    USDTTabContent,
    USDTCreateModalContent,
  },
  computed: {
    // ---
  },
  data() {
    return {
      requisitesColumns,
      pagination: { pageSize: 10 },
      searchField: 'id',
      searchValue: '',
      submitLoading: false,
      requisitesLoading: false,
      addRequisiteModal: false,
      commentModal: false,
      requisites: [],
      requisiteObj: null,
      saleRequisite: null,
      USDTWallet: null,
      activeTab: 'buy',
    }
  },
  mounted() {
    this.fetch()
    // this.getRequisites()
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    closeRequisiteModal() {
      this.requisiteObj = null
      this.addRequisiteModal = false

      if (this.activeTab === 'usdt') {
        this.$refs.USDTTabContent.fetchPayoutWallets()
        this.USDTWallet = null
      }
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    emitRequisitesChange() {
      this.$emit('changeModalFunction', this.requisites.length, this.accountObj)
    },
    showAddRequisiteModal() {
      this.requisiteObj = null
      this.addRequisiteModal = true
    },
    openUSDTWalletModal(requisite) {
      this.USDTWallet = requisite
      this.addRequisiteModal = true
    },
    showEditSaleRequisiteModal(requisite) {
      this.saleRequisite = requisite
      this.addRequisiteModal = true
    },
    showEditRequisiteModal(requisite) {
      this.requisiteObj = requisite
      this.addRequisiteModal = true
    },
    showComment(comment) {
      Modal.info({
        title: 'Комментарий',
        content: comment,
        onOk() {
        },
      })
    },
    closeSaleRequisiteModal() {
      this.$refs.saleRequisitesTable.fetchRequisitesB()
      this.addRequisiteModal = false
      setTimeout(() => {
        this.saleRequisite = null
      }, 0)
    },
    requisiteModalSubmit(requisite, edit) {
      if (edit) {
        const psIndex = this.requisites.findIndex(obj => obj.id === requisite.id)
        this.requisites[psIndex] = requisite
        // console.log(requisite)
        this.emitRequisitesChange()
      } else {
        // this.requisites.unshift(requisite)
        this.refresh().then(() => {
          this.emitRequisitesChange()
        })
      }
      this.addRequisiteModal = false
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    async fetch(params = {}) {
      this.requisitesLoading = true
      const reqData = { results: 20, ...params }
      ApiService.getAccountRequisites(this.accountObj.id, reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.meta.total
        this.requisitesLoading = false
        this.requisites = response.data
        this.pagination = pagination
        // console.log('fetch ok', response.data)
      }).catch(error => {
        console.log(error)
        this.requisitesLoading = false
      })
    },
    async refresh() {
      return this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    async requisiteActiveState(checked, event) {
      const reqId = event.target.getAttribute('reqId')
      return ApiService.changeRequisiteActive(reqId, +checked).then((response) => {
        this.$notification.success({
          message: `${this.$t('messages.requisiteStatusUpdated')}`,
          description: response.address,
        })
        const reqIndex = this.requisites.findIndex(obj => obj.id === reqId)
        this.requisites[reqIndex] = response
        this.emitRequisitesChange()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorChangingStatus')}`,
          description: error.message,
        })
      })
    },
    async requisiteSetDefault(requisite) {
      return ApiService.setRequisiteDefault(requisite.id).then(() => {
        this.$notification.success({
          message: `${this.$t('buttons.btnDefault')}`,
          description: `${requisite.psystem.name}, ${requisite.address}`,
        })
        const ind = this.requisites.findIndex((req) => req.id === requisite.id)
        this.requisites.forEach((requisite) => {
          requisite.as_any_bank = false
        })
        this.requisites[ind].as_any_bank = true
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorRequisiteSetDefault')}`,
          description: `${requisite.psystem.name}, ${requisite.address}`,
        })
      })
    },
    async deleteRequisite(requisite) {
      return ApiService.deleteRequisite(requisite.id).then((response) => {
        this.$notification.success({
          message: `${this.$t('messages.requisitesDeleted')}`,
          description: `${requisite.psystem.name}, ${requisite.address}`,
        })
        const reqIndex = this.requisites.findIndex(obj => obj.id === requisite.id)
        this.requisites.splice(reqIndex, 1)
        this.emitRequisitesChange()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorDeletingRequisite')}`,
          description: error.message,
        })
      })
    },
    randomString(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
  },
}
</script>

<style>
.requisites-modal {
  .ant-modal {
    /*min-width: 1400px;*/
  }
}
</style>
