<template>
  <div>
    <a-form-model
      ref="createOperationForm"
      :model="createOperationForm"
      :rules="createOperationRules"
    >
      <a-row>
        <div class="text-left pb-4">
          <a-radio-group v-model="operationType" button-style="solid">
            <a-radio-button value="deposit">
              <a-icon type="arrow-up" /> Deposit
            </a-radio-button>
            <a-radio-button value="withdraw">
              <a-icon type="arrow-down" /> Withdraw
            </a-radio-button>
          </a-radio-group>
        </div>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="amount" label="Amount" prop="amount">
            <a-input-number
              v-model="createOperationForm.amount"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="1"
              :max="100000"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          Cancel
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          Create
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'createOperation',
  props: ['operationInfo', 'accountId'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,

      createOperationForm: {
        amount: this.operationInfo ? this.operationInfo.amount : 1,
      },
      createOperationRules: {
        amount: [{ required: true, message: 'Please enter an amount', trigger: 'blur' }],
      },
      operationType: 'deposit',
    }
  },
  mounted () {
    //
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.createOperationForm.validate(valid => {
        if (valid) {
          this.createOperationSubmit()
        } else {
          console.log('error submit')
          return false
        }
      })
    },
    createOperationSubmit() {
      this.submitLoading = true
      return ApiService.storeAgentAdminOperation(this.accountId, { amount: this.createOperationForm.amount, type: this.operationType })
        .then((response) => {
          this.$notification.success({
            message: 'Operation succeeded',
            description: `Amount: ${response.amount} $`,
          })
          this.submitLoading = false
          this.submitModal(response)
        })
        .catch(error => {
          this.$notification.error({
            message: 'Error during operation',
            description: error.message,
          })
          this.submitLoading = false
        })
    },
  },
}
</script>

<style scoped>
</style>
