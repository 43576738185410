<template>
  <div class="accounts-content">
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="h5 mb-0"><font-awesome-icon icon="user-tie" class="mt-1 mr-1 font-size-24" /> {{ $t('common.agents') }}</span>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-select v-model="projectId" :placeholder="$t('common.project')" class="width-150" @change="handleChange">
            <a-select-option :value="0" >
              [{{ $t('pre.all') }} {{ $t('common.projects') }}]
            </a-select-option>
            <a-select-option v-for="project in projectList" :key="project.id" >
              <a-badge status="success" v-if="project.is_active" />
              <a-badge color="grey" v-else />
              {{ project.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-select v-model="currencyId" :placeholder="$t('common.currency')" class="width-150" @change="handleChange">
            <a-select-option :value="0" >
              [{{ $t('pre.all') }} {{ $t('common.currencies') }}]
            </a-select-option>
            <a-select-option v-for="currency in currencies" :key="currency.id" >
              <a-badge status="success" v-if="currency.is_active" />
              <a-badge color="grey" v-else />
              {{ currency.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-select v-model="agentType" placeholder="[Type]" class="width-150" @change="handleChange">
            <a-select-option value="regular">
              <i class="fe fe-user" /> Regular
            </a-select-option>
            <a-select-option value="dev">
              <i class="fe fe-code" /> Developer
            </a-select-option>
            <a-select-option value="system">
              <i class="fe fe-settings" /> System
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-auto">
          <search-input :field="search" @searchFunction="handleSearchChange" />
        </div>
      </div>
      <div class="mt-1 mb-3">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="4">
            <a-statistic :title="`${$t('common.sum')} ${$t('common.balance')}`" :value="statData.balance"
                         style="margin-right: 50px">
            </a-statistic>
          </a-col>
          <a-col :span="4">
            <a-statistic :title="`${$t('common.sum')} ${$t('common.debt')}`" :value="statData.debt"
                         style="margin-right: 50px">
            </a-statistic>
          </a-col>
          <a-col :flex="1"></a-col>
          <a-col>
            <a-button type="primary" @click="showAccountModal">
              <a-icon type="plus" /> {{ $t('buttons.btnAdd') }} {{ $t('common.agent') }}
            </a-button>
          </a-col>
          <a-col>
            <a-button :loading="accountsLoading" @click="refreshList"><a-icon type="reload" /> {{ $t('buttons.btnRefresh') }}</a-button>
          </a-col>
        </a-row>
      </div>
      <a-table
        :columns="accountColumns"
        :dataSource="accountsData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="accountsLoading"
        @change="handleTableChange"
      >
        <template v-slot:account="account, record">
          <span class="mr-1">
            <img v-if="record.sex === 'male'" src="/resources/images/avatars/user-agent-m.png" width="18"/>
            <img v-else src="/resources/images/avatars/user-agent-w.png" width="18"/>
          </span>
          <router-link
            tag="a"
            class="button"
            :to="{name: 'transactions', params: { accountId: record.id }}"
          >
            <a href="javascript:" class="font-weight-bolder">{{ record.name }}</a>
          </router-link>
          <div>
            <span v-if="record.contact" style="margin-left: 2px;">
              <a-tooltip :title="`Contact via Telegram: @${record.contact.telegram}`" placement="bottom">
                <span v-if="record.contact.telegram" class="font-size-14 mr-2">
                  <a :href="`https://t.me/${record.contact.telegram.replace('@','')}`" target="_blank">
                    <font-awesome-icon :icon="['fab', 'telegram']" class="text-gray-5" />
                  </a>
                </span>
              </a-tooltip>
              <a-tooltip :title="`Contact via Email: ${record.contact.email}`" placement="bottom">
                <span v-if="record.contact.email" class="font-size-14 mr-2">
                  <a :href="`mailto:${record.contact.email}`" target="_blank">
                    <a-icon type="mail" class="text-gray-6" />
                  </a>
                </span>
              </a-tooltip>
            </span>
            <span class="font-size-10 pr-2 text-gray-5">#{{ record.id }}</span>
          </div>
          <div class="mt-1">
            <a-tooltip :title="`${$t('common.total')} ${record.transactions_count.total} ${$t('common.orders')}`" placement="bottom">
              <a-badge
                :overflow-count="9999"
                :count="record.transactions_count.total"
                :number-style="{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
                marginLeft: '0px',
                marginTop: '-4px',
                fontSize: '10px'
              }"
              />
            </a-tooltip>
            <a-tooltip :title="`${record.transactions_count.paid} ${$t('statuses.payment.paid')} ${$t('common.orders')}`" placement="bottom">
              <a-badge v-if="record.transactions_count.paid > 0"
                       :overflow-count="9999"
                       :count="record.transactions_count.paid"
                       :number-style="{
                backgroundColor: '#fff',
                color: '#41b883',
                boxShadow: '0 0 0 1px #41b883 inset',
                marginLeft: '4px',
                marginTop: '-4px',
                fontSize: '10px'
              }"
              />
            </a-tooltip>
            <a-tooltip :title="`Order rejections this month`" placement="bottom">
              <a-badge
                v-if="record.rejections.count"
                :overflow-count="9999"
                :count="record.rejections.count"
                :number-style="{
                backgroundColor: '#fff',
                color: '#f5222e',
                boxShadow: '0 0 0 1px #f5222e inset',
                marginLeft: '4px',
                marginTop: '-4px',
                fontSize: '10px'
              }"
              />
            </a-tooltip>
          </div>
          <a-tooltip v-if="record.is_coward" :title="$t('common.coward')" placement="bottom">
            <span class="float-right">🐤</span>
          </a-tooltip>
        </template>
        <span slot="psystems" slot-scope="psystems, record">
          <div v-if="psystems.length>0">
            <span v-for="ps in psystems" :key="ps.id" class="p-1">
              <a-tooltip :title="ps.name" placement="bottom">
                <img :src="ps.img_path" height="24" width="24" />
              </a-tooltip>
            </span>
          </div>
          <div class="mt-1 font-size-12 text-gray-6" v-if="record.currencies.length>0" >
            {{ $t('common.currency') }}:
            <a-tag class="ml-0 mr-1">{{ currencies.find((cur) => cur.id === record.currency_id).name }}</a-tag>
<!--            <span v-for="cr in record.currencies" :key="cr.id" >-->
<!--              <a-tag class="ml-0 mr-1">{{ cr.name }}</a-tag>-->
<!--            </span>-->
          </div>
        </span>
        <template v-slot:rotate="rotate, record">
          <div v-if="rotate !== true && record.stop_reason && record.stop_reason === 1">
            <a-tooltip :title="`${$t('common.stoppedBy')}${$t('by.admin')}`" placement="bottom">
              <span class="small text-red font-weight-bolder">stopped</span>
            </a-tooltip>
          </div>
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
<!--              <a-icon type="bars"/>-->
              <div :class="`m-1 buy-rotation-dot rotation-dot ${(rotate === true) ? ((record.settings.common.rotation.buy_enabled === true) ? 'bg-success' : 'bg-danger') : 'bg-gray-4' }`"></div>
            </a>
            <a-menu slot="overlay">
              <a-menu-item :key="`agent_${record.id}_buy_rotation_state`" disabled>
                <span>
                  {{ (record.settings.common.rotation.buy_enabled === true) ? 'Started' : 'Stopped' }}
                </span>
                <span class="small d-block">
                  Reason: {{ (record.settings.common.rotation.buy_enabled === true) ? record.settings.common.rotation.buy_start_reason : record.settings.common.rotation.buy_stop_reason }}
                </span>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item :key="`agent_${record.id}_buy_rotation_action`">
                <a href="javascript:" @click="startStopBuyRotation(record.id, +!record.settings.common.rotation.buy_enabled)">
                  <span v-if="record.settings.common.rotation.buy_enabled === false">
                    <font-awesome-icon icon="play" class="text-success" />
                    <span class="pl-2">Start</span>
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="stop" class="text-danger" />
                    <span class="pl-2">Stop</span>
                  </span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <div :class="`m-1 sell-rotation-dot rotation-dot ${(rotate === true) ? ((record.settings.common.rotation.sell_enabled === true) ? 'bg-success' : 'bg-danger') : 'bg-gray-4' }`"></div>
        </template>
        <template v-slot:debt="debt, record">
          <span v-if="record.settings.common.payments">
            <a-tooltip :title="`${$t('common.limit')}: ${(record.settings.common.payments.buy_credit + record.settings.common.payments.buy_limit).toFixed(2)}$\n${$t('common.available')}: ${record.debt_f}$ ${$t('pre.of')} ${(record.settings.common.payments.buy_credit + record.settings.common.payments.buy_limit).toFixed(2)}$`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
<!--            <a-tooltip :title="`${$t('common.limit')}: ${record.max_debt_inc ? record.max_debt + '$ + ' + record.balance : record.max_debt}$\n${$t('common.available')}: ${record.debt_f}$ ${$t('pre.of')} ${(record.max_debt_inc ? record.max_debt + record.balance : record.max_debt).toFixed(2)}$`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">-->
              <a-progress class="font-size-10" type="circle"
                          :percent="(record.debt_f / (record.settings.common.payments.buy_credit + record.settings.common.payments.buy_limit)) * 100"
                          :status="`${getDebtProgressStatus((record.debt_f / (record.settings.common.payments.buy_credit + record.settings.common.payments.buy_limit)) * 100)}`"
                          :width="60" >
                <template slot="format">
                  <div class="accounts-table_dept-circle-values">
                    <span class="accounts-table_dept-circle-limit">{{ (record.debt_f).toFixed(2) }}$</span>
                    <span class="accounts-table_dept-circle-dept" v-if="record.debt > 0">{{ record.debt }}$</span>
                  </div>
                </template>
              </a-progress>
            </a-tooltip>
          </span>
        </template>
        <template v-slot:balance="balance, record">
          <div><a href="javascript:" @click="showBillingModal(record)">$ <b>{{ balance }}</b></a></div>
          <div class="small mt-0 text-gray-5" v-if="record.current_plan">
            <a-tooltip :title="`${$t('common.rewards')} ${$t('common.plan')}`" placement="bottom" >
              <span class="font-size-10"><a-icon type="trophy" /> {{ `${record.current_plan.fixed_amount}+${record.current_plan.percent_amount}%` }}</span>
            </a-tooltip>
          </div>
          <div class="small mt-2 text-gray-5">
            <div>
              <a-tooltip class="d-flex" :title="`${$t('common.time')} ${$t('common.inRotation')}${$t('pre.for')}${$t('common.thisMonth')}`" placement="bottom" >
                <div class="width-20">
                  <a-badge>
                    <span slot="count" class="font-size-10">m</span>
                    <a-icon type="clock-circle" class="small" />
                  </a-badge>
                </div>
                <div>
                  {{ record.rotation_time.split(' ')[0] }}h {{ record.rotation_time.split(' ')[1] }}m
                </div>
              </a-tooltip>
            </div>
            <div>
              <a-tooltip class="d-flex" :title="'Time in rotation for this week'" placement="bottom" >
                <div class="width-20">
                  <a-badge>
                    <span slot="count" class="font-size-10">w</span>
                    <a-icon type="clock-circle" class="small" />
                  </a-badge>
                </div>
                <div>
                  {{ record.rotation_time_week.split(' ')[0] }}h {{ record.rotation_time_week.split(' ')[1] }}m
                </div>
              </a-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:projects="projects">
          <div v-if="projects && projects.length > 0 && projectList">
            <div v-for="projectId in projects" :key="projectId" class="d-block"><a-tag>{{ projectList.find(it => it.id === projectId)?.name }}</a-tag></div>
          </div>
          <span v-else>-</span>
        </template>
<!--        <span slot="bch_comm" slot-scope="bch_comm">-->
<!--          {{ bch_comm }}%-->
<!--        </span>-->
<!--        <template v-slot:min_usd="min_usd">-->
<!--          {{ min_usd }}$-->
<!--        </template>-->
<!--        <span slot="contact" slot-scope="contact">-->
<!--        </span>-->
        <span slot="operation" slot-scope="text, record">
          <div class="text-right">
            <span v-if="rotateLoading && rotateId === record.id"><a-icon type="loading" /></span>
            <span v-else>
              <span v-if="record.in_rotate === false">
                <a v-if="record.is_banned === false" href="javascript:" @click="changeRotation(record.id, 1)">
                  <a-tooltip :title="`${$t('buttons.btnChange')}${$t('pre.to')}${$t('common.online')}`" placement="bottom">
                    <font-awesome-icon icon="play" class="text-success font-size-18" />
                  </a-tooltip>
                </a>
                <font-awesome-icon v-else icon="play" class="text-gray-4 font-size-18" />
              </span>
              <span v-else>
                <a href="javascript:" @click="changeRotation(record.id, 0)">
                  <a-tooltip :title="$t('buttons.btnStop')" placement="bottom">
                    <font-awesome-icon icon="stop" class="text-danger font-size-18" />
                  </a-tooltip>
                </a>
              </span>
            </span>
            <span class="ml-3" v-if="1 === 0">
              <a-tooltip :title="$t('common.billing')" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showBillingModal(record)">
                  <font-awesome-icon icon="donate" class="text-gray-6 font-size-18" />
                </a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip :title="`${$t('common.requisites')} (${record.requisites_count})`" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showRequisitesModal(record)">
                  <font-awesome-icon v-if="record.requisites_count > 0" icon="wallet" class="text-gray-6 font-size-18" />
                  <font-awesome-icon v-else icon="wallet" class="text-gray-5 font-size-18" />
                </a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip :title="$t('buttons.btnSettings')" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showEditAccountModal(record)"><a-icon type="control" theme="filled" /></a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip :title="$t('buttons.btnDelete')" placement="bottom">
                <a-popconfirm
                  :title="`${$t('buttons.btnDelete')} ${$t('common.agent')} ?`"
                  @confirm="deleteAccount(record)"
                >
                  <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </span>
      </a-table>
      <a-modal v-model="newAccountModal"
               :destroyOnClose="true"
               :title="editAccountObj ? `${$t('buttons.btnEdit')} ${$t('common.agent')} ${editAccountObj.name}` : `${$t('buttons.btnAdd')} ${$t('buttons.btnNew')} ${$t('common.agent')}`"
               :footer="null"
               class="add-account-modal"
               width="720px"
      >
        <add-account-component
          :account-info="editAccountObj"
          @closeModalFunction="closeAccountModal"
          @submitModalFunction="accountModalSubmit"
          @refreshListFunction="refreshList"
        />
      </a-modal>
      <a-modal v-model="requisitesModal"
               :destroyOnClose="true"
               :footer="null"
               class="requisites-modal"
               width="1400px"
      >
        <account-requisites-component
          :account-obj="requisitesObj"
          @closeModalFunction="closeAccountModal"
          @changeModalFunction="requisiteModalChange"
        />
      </a-modal>
      <a-modal v-model="billingModal"
               :destroyOnClose="true"
               :footer="null"
               :closable="false"
               class="billing-modal"
               width="780px"
      >
        <billing-component
          :account-obj="billingObj"
          @closeModalFunction="closeBillingModal"
          @changeModalFunction="billingModalChange"
        />
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apiClient from '@/services/axios'
// import psMix from '@/services/psMix.js'
import moment from 'moment-timezone'
import addAccountComponent from '@/views/accounts/addModal.vue'
import accountRequisitesComponent from '@/views/accounts/requisites/requisitesModal.vue'
import searchInput from '@/components/input/InputSearch/index.vue'
import billingComponent from '@/views/accounts/billing/billingModal.vue'
import ApiService from '@/services/api/api.service'

const accountColumns = [
  {
    title: 'Agent',
    dataIndex: 'name',
    key: 'name',
    width: '14%',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Methods',
    dataIndex: 'psystems',
    key: 'psystems',
    width: '15%',
    scopedSlots: { customRender: 'psystems' },
  },
  {
    title: 'Status',
    dataIndex: 'in_rotate',
    key: 'in_rotate',
    width: '8%',
    align: 'center',
    scopedSlots: { customRender: 'rotate' },
  },
  {
    title: 'Debt',
    dataIndex: 'debt',
    key: 'debt',
    width: '8%',
    align: 'center',
    scopedSlots: { customRender: 'debt' },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    width: '10%',
    scopedSlots: { customRender: 'balance' },
  },
  {
    title: 'Project',
    dataIndex: 'project_ids',
    key: 'project_ids',
    width: '8%',
    scopedSlots: { customRender: 'projects' },
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {
    addAccountComponent,
    accountRequisitesComponent,
    billingComponent,
    searchInput,
  },
  computed: {
    ...mapGetters(['currencies', 'projectList']),
  },
  data() {
    return {
      accountsData: [],
      accountsLoading: false,
      statData: {
        debt: 0,
        balance: 0,
      },
      accountColumns,
      moment,
      rotateLoading: false,
      rotateId: undefined,
      restrictionsLoading: false,
      restrictionsId: undefined,
      newAccountModal: false,
      requisitesModal: false,
      billingModal: false,
      editAccountObj: undefined,
      requisitesObj: undefined,
      billingObj: undefined,
      pagination: { pageSize: 50, current: 1 },
      search: '',
      projectId: 0,
      currencyId: 0,
      agentType: 'regular',
    }
  },
  mounted () {
    // this.connectToChannel()
    this.updateTableValues()
    this.getStatData()
  },
  methods: {
    async updateTableValues(pagination) {
      const pager = { ...this.pagination }
      if (pagination) {
        pager.current = pagination.current
        this.pagination = pager
      }
      const params = {
        results: this.pagination.pageSize,
        page: this.pagination.current,
        project_id: this.projectId,
        currency_id: this.currencyId,
        search: this.search,
        type: this.agentType,
      }
      await this.fetchAccounts(params)
    },
    handleTableChange(pagination, filters, sorter) {
      this.updateTableValues(pagination)
    },
    handleChange() {
      this.pagination.current = 1
      this.refreshList()
    },
    handleSearchChange(e = '') {
      this.search = e
      this.handleChange()
    },
    refreshList() {
      this.updateTableValues(null, null)
      this.getStatData()
    },
    async getStatData() {
      try {
        const response = await ApiService.getAccountsDebtAndBalanceDigits({
          project_id: this.projectId,
          search: this.search,
          currency_id: this.currencyId,
        })
        this.statData = response
        console.log(this.statData)
      } catch (e) {
      }
    },
    async fetchAccounts(params = {}) {
      this.accountsLoading = true
      const reqData = { results: 20, ...params }
      return ApiService.getAccounts(reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.accountsLoading = false
        this.accountsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.accountsLoading = false
      })
    },
    connectToChannel() {
      const token = '2d7e5c6a9ed89a9f92709df187a16fb81vDxWIvk'
      window.Echo.channel(token).listenToAll((event, e) => {
        console.log(event, e)
        if (event === '.PaymentAccepted') {
          console.log('Агент принял заявку')
        }
        if (event === '.PaymentCanceled') {
          console.log('Заявка отменена')
        }
        if (event === '.ConfirmPaymentIncome') {
          console.log('Агент подтвердил платеж')
        }
        if (event === '.PromocodeActivated') {
          console.log('SUCCESS! Промокод успешно активирован')
        }
      })
      console.log('connected')
    },
    showAccountModal() {
      this.editAccountObj = undefined
      this.newAccountModal = true
    },
    showEditAccountModal(obj) {
      this.editAccountObj = obj
      this.newAccountModal = true
    },
    showRequisitesModal(obj) {
      this.requisitesObj = obj
      this.requisitesModal = true
    },
    showBillingModal(obj) {
      this.billingObj = obj
      this.billingModal = true
    },
    closeAccountModal() {
      this.newAccountModal = false
    },
    closeBillingModal(stats, accountId) {
      if (stats && stats?.balance && accountId) {
        const psIndex = this.accountsData.findIndex(obj => obj.id === accountId)
        this.accountsData[psIndex].balance = stats.balance
      }
      this.billingModal = false
    },
    closeRequisitesModal() {
      this.requisitesModal = false
    },
    accountModalSubmit(account, edit) {
      if (edit) {
        const psIndex = this.accountsData.findIndex(obj => obj.id === account.id)
        this.accountsData[psIndex] = account
        console.log(account)
      } else {
        this.accountsData.unshift(account)
      }
      this.newAccountModal = false
    },
    requisiteModalChange(requisitesCount, account) {
      const accIndex = this.accountsData.findIndex(obj => obj.id === account.id)
      this.accountsData[accIndex].requisites_count = requisitesCount
    },
    billingModalChange(changed, account) {
      //
    },
    deleteAccount(acc) {
      const url = '/admin/accounts/' + acc.id
      apiClient.delete(url).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: `${this.$t('common.agent')} ${this.$t('statuses.deleted')}`,
          description: acc.name,
        })
        const accIndex = this.accountsData.findIndex(obj => obj.id === acc.id)
        this.accountsData.splice(accIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorDeletingAgent')}`,
          description: error.message,
        })
      })
    },
    startStopBuyRotation(agentId, state) {
      return ApiService.updateAgentBuyRotationState(agentId, state).then(() => {
        const accIndex = this.accountsData.findIndex(obj => obj.id === agentId)
        this.accountsData[accIndex].settings.common.rotation.buy_enabled = !!state
        if (state === 1) {
          this.accountsData[accIndex].settings.common.rotation.buy_start_reason = 'admin'
        } else {
          this.accountsData[accIndex].settings.common.rotation.buy_stop_reason = 'admin'
        }
        this.accountsData[accIndex].settings.common.rotation.buy_enabled = !!state
        this.$message.success(`${this.$t('common.agent')} Buy Rotation ${(state === 1) ? 'Started' : 'Stopped'}`)
        console.log(`Agent ${agentId} buy rotation change to ` + state)
      }).catch(error => {
        console.log(error)
        this.$message.error(`Can not ${(state === 1) ? 'Start' : 'Stop'} Buy Rotation`)
      })
    },
    changeRotation(accountID, status) {
      this.rotateLoading = true
      this.rotateId = accountID
      const notiMsg = [
        `${this.$t('statuses.changed')}${this.$t('pre.to')}${this.$t('common.offline')}`,
        `${this.$t('statuses.changed')}${this.$t('pre.to')}${this.$t('common.online')}`,
      ]
      const url = '/admin/accounts/' + accountID + '/rotation/' + status
      apiClient.post(url).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: notiMsg[status],
          description: `${this.$t('common.agent')} "` + response.data.data.name + '"',
        })
        const accIndex = this.accountsData.findIndex(obj => obj.id === accountID)
        this.accountsData[accIndex].in_rotate = response.data.data.in_rotate
        this.accountsData[accIndex].stopped_by_owner = response.data.data.stopped_by_owner
        this.accountsData[accIndex].stop_reason = response.data.data.stop_reason
        this.rotateLoading = false
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorChangingAgentStatus')}`,
          description: error.message,
        })
        this.rotateLoading = false
      })
    },
    getDebtProgressStatus(percent) {
      if (percent > 80) { return 'success' }
      return (percent < 20) ? 'exception' : 'normal'
    },
  },
}
</script>
<style>
.account-status-dot .ant-badge-status-dot {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.ant-progress-text {
  font-size: 11px !important;
  font-weight: bolder;
}
</style>
<style scoped>
  .accounts-table_dept-circle-values {
    display: flex;
    flex-direction: column !important;
    gap: 2px;
  }
  .accounts-table_dept-circle-limit {
    color: green;
  }
  .accounts-table_dept-circle-dept {
    color: red;
  }
  .ant-progress-text {
    font-size: 10px;
  }
  .rotation-dot {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .sell-rotation-dot {
    border-radius: 1px;
  }
  .buy-rotation-dot {
    border-radius: 50%;
  }
</style>
