<template>
  <div>
    <a-input-search
      v-model="value"
      :placeholder="$t('placeholders.selectSearch')"
      allowClear
      @search="handleSearch"
      @keydown.enter="handleSearch"
    />
  </div>
</template>

<script>
export default {
  name: 'inputSearch',
  props: {
    field: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: this.field,
    }
  },
  methods: {
    handleSearch() {
      this.$emit('searchFunction', this.value)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
