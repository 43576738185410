<template>
  <a-modal
    :width="700"
    v-model="localVisible"
    title="Editing Details"
    :afterClose="handleVisibleChange"
    :footer="null"
  >
    <a-table
      :columns="columns"
      :data-source="notes"
      :row-key="record => record.id"
      :scroll="{ y: 300 }"
      :bordered="false"
      :pagination="false"
      size="small"
      class="ant-small-table"
    >
    </a-table>
  </a-modal>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localVisible: this.visible,
      columns: [
        {
          title: 'User',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Note',
          dataIndex: 'note',
          key: 'note',
        },
        {
          title: 'Noted At',
          dataIndex: 'created_at',
          key: 'created_at',
          scopedSlots: { customRender: 'created_at' },
        },
      ],
    }
  },
  watch: {
    visible(newValue) {
      this.localVisible = newValue
    },
  },
  methods: {
    handleVisibleChange() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
