import { render, staticRenderFns } from "./NoteEditorsModal.vue?vue&type=template&id=2e70163c&scoped=true"
import script from "./NoteEditorsModal.vue?vue&type=script&lang=js"
export * from "./NoteEditorsModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e70163c",
  null
  
)

export default component.exports