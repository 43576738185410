<template>
  <div>
    <div slot="title" class="mb-4">
      <div class="d-flex">
        <h6>Balance - {{ accountName }}</h6>
        <div class="ml-auto">
            <div class="close-modal-icon">
              <a href="javascript:" class="text-gray-6 font-size-16" @click="closeModal"><a-icon type="close" /></a>
            </div>
        </div>
      </div>
    </div>
    <a-form-model
      ref="addRequisiteForm"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="agent" label="Agent" prop="agent">
            <a-select v-model="accountId" placeholder="Select agent" @change="handleAccountChange">
              <a-select-option v-for="account in accountsData" :key="account.id" >
                {{ account.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-2">
          <a-form-model-item ref="month" label="Month" prop="month">
            <a-select v-model="selectedMonth" placeholder="Select a month" @change="handleMonthChange">
              <a-select-option :value="0" :key="0">
                All period
              </a-select-option>
              <a-select-option v-for="(monthName, index) in availableMonths" :key="index+1" >
                {{ monthName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-3" v-if="!statsLoading">
        <a-col :span="8" class="text-center">
          <a-statistic
            title="Current balance"
            :value="stats.balance"
            :precision="2"
            suffix="$"
            class="text-gray-5"
            style="margin-right: 10px"
          >
            <template #prefix>
              <a-icon type="wallet" />
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="8" class="text-center">
          <a-statistic
            title="Cash flow"
            :value="stats.payments.value"
            :precision="2"
            suffix="$"
            class="text-gray-5"
            style="margin-right: 10px"
          >
            <template #prefix>
              <a-icon type="sync" />
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="8" class="text-center">
          <div style="color: rgba(0,0,0,0.45)" class="mb-1">Work time for the period</div>
          <div class="font-size-24">
            <a-icon type="clock-circle" class="mr-2" />
            <span>{{ stats.rotation.values.h }}<span class="font-size-16">ч</span></span>
            <span class="ml-2 font-size-16">{{ stats.rotation.values.m }}<span class="font-size-16">м</span></span>
          </div>
        </a-col>
      </a-row>
      <div v-else class="width-100p text-center mt-1 mb-4">
        <a-spin size="large" />
      </div>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-card class="billing-stat-card">
            <a-row v-if="!statsLoading">
              <a-col :span="12">
                <a-statistic
                  title="Rewards sum"
                  :value="stats.rewards.value"
                  :precision="2"
                  suffix="$"
                  :value-style="{ color: '#3f8600' }"
                  style="margin-right: 10px"
                >
                  <template #prefix>
                    <a-icon type="arrow-up" />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="12">
                <a-statistic
                  title="Rewards count"
                  :value="stats.rewards.count"
                  :precision="0"
                  suffix="шт"
                  style="margin-left: 20px"
                >
                  <template #prefix>
                    <a-icon type="file-done" />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
            <div v-else class="width-100p text-center mt-1 mb-4">
              <a-spin size="large" />
            </div>
            <div class="mt-2">
              <a-table
                :columns="rewardsColumns"
                :data-source="rewardsData"
                :row-key="record => record.id"
                :loading="rewardsLoading"
                :pagination="rewardsPagination"
                @change="handleRewardsChange"
                :scroll="{ y: 280 }"
                :bordered="false"
                size="small"
                class="ant-small-table"
              >
                <template v-slot:charged_at="charged_at">
                  {{ $moment.parseZone(charged_at).format('DD.MM.YY') }}
<!--                  <span class="small text-gray-5"><a-icon type="clock-circle" /> {{ $moment(charged_at).format('HH:mm') }}</span>-->
                </template>
                <template v-slot:amount="amount, record">
                  {{ amount }} $
                  <span class="text-gray-5" v-if="record.amount_f">
                    ({{ record.amount_f }}+{{ record.amount_p }})
                  </span>
                </template>
              </a-table>
            </div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card class="billing-stat-card">
            <div class="d-flex" v-if="!statsLoading">
              <a-statistic
                title="Deposits"
                :value="stats.deposits.value"
                :precision="2"
                suffix="$"
                class="demo-class pr-3"
                :value-style="{ color: '#3f8600' }"
              >
                <template #prefix>
                  <a-icon type="arrow-up" />
                </template>
              </a-statistic>
              <a-statistic
                title="Withdrawals"
                :value="stats.withdrawals.value"
                :precision="2"
                suffix="$"
                class="demo-class pl-5"
                :value-style="{ color: '#cf1322' }"
              >
                <template #prefix>
                  <a-icon type="arrow-down" />
                </template>
              </a-statistic>
              <div class="ml-auto">
                <span class="font-size-18">
                  <a-tooltip title="Create" placement="bottom">
                    <a href="javascript:" @click="showCreateOperationModal"><a-icon type="plus" class="text-primary" /></a>
                  </a-tooltip>
                </span>
              </div>
            </div>
            <div v-else class="width-100p text-center mt-1 mb-4">
              <a-spin size="large" />
            </div>
            <div class="mt-2">
              <a-table
                :columns="operationsColumns"
                :data-source="operationsData"
                :row-key="record => record.id"
                :loading="operationsLoading"
                :pagination="operationsPagination"
                @change="handleOperationsChange"
                :scroll="{ y: 360 }"
                :bordered="false"
                size="small"
                class="ant-small-table"
                >
                <template v-slot:created_at="created_at">
                  {{ $moment.parseZone(created_at).format('DD.MM.YY') }}
                  <span class="small pl-2 text-gray-5"><a-icon type="clock-circle" />
                    {{ $moment.parseZone(created_at).format('HH:mm') }}
                  </span>
                </template>
                <template v-slot:amount="amount">
                  <a-icon v-if="amount.type === 'deposit'" style="color:#3f8600" type="arrow-up" />
                  <a-icon v-if="amount.type === 'withdraw'" style="color:#cf1322" type="arrow-down" />
                  {{ amount.value }} $
                </template>
              </a-table>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-form-model>
    <a-modal v-model="createOperationModal"
             :destroyOnClose="true"
             title="Create operation"
             :footer="null"
             class="add-accrual-modal"
             width="480px"
    >
      <create-operation-component
        :operation-info="operationObj"
        :account-id="accountId"
        @closeModalFunction="closeOperationModal"
        @submitModalFunction="operationModalSubmit" />
    </a-modal>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import createOperationComponent from '@/views/accounts/billing/createOperationModal.vue'
import ApiService from '@/services/api/api.service'

const operationsColumns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '60%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '40%',
    scopedSlots: { customRender: 'amount' },
  },
]
const rewardsColumns = [
  {
    title: 'Date',
    dataIndex: 'charged_at',
    key: 'charged_at',
    width: '30%',
    scopedSlots: { customRender: 'charged_at' },
  },
  {
    title: 'Rewards',
    dataIndex: 'trec_id',
    key: 'trec_id',
    width: '25%',
    scopedSlots: { customRender: 'payment' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '45%',
    align: 'left',
    scopedSlots: { customRender: 'amount' },
  },
]

export default {
  name: 'billingModal',
  props: ['accountObj'],
  components: {
    createOperationComponent,
  },
  computed: {
    availableMonths() {
      const avm = []
      const currentMonth = new Date().getMonth()
      this.monthNames.forEach(function (value, i) {
        if (i <= currentMonth) { avm.push(value) }
      })
      console.log('avm', avm)
      return avm
    },
    accountName() {
      const acIndex = this.accountsData.findIndex(obj => obj.id === this.accountId)
      return this.accountsData[acIndex]?.name ?? ''
    },
  },
  data() {
    return {
      submitLoading: false,
      statsLoading: false,
      rewardsLoading: false,
      operationsLoading: false,
      createOperationModal: false,
      operationsData: [],
      rewardsData: [],
      accountsData: [],
      accountId: this.accountObj.id,
      operationObj: null,
      operationsColumns,
      rewardsColumns,
      rewardsPagination: { pageSize: 10 },
      operationsPagination: { pageSize: 8 },
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      selectedMonth: new Date().getMonth() + 1,
      stats: {
        balance: 0,
        rewards: {
          count: 0,
          value: 0,
        },
        withdrawals: {
          count: 0,
          value: 0,
        },
        deposits: {
          count: 0,
          value: 0,
        },
        payments: {
          count: 0,
          value: 0,
        },
        rotation: {
          values: {
            h: 0,
            m: 0,
            s: 0,
          },
        },
      },
    }
  },
  mounted () {
    this.getAccounts().then(() => this.refreshData())
  },
  methods: {
    handleMonthChange() {
      console.log(this.selectedMonth)
      this.refreshData()
    },
    handleAccountChange() {
      console.log(this.accountId)
      this.refreshData()
    },
    refreshData() {
      this.getStat().then(() => { this.fetchRewards(); this.fetchOperations() })
    },
    closeModal() {
      this.$emit('closeModalFunction', this.stats, this.accountId)
    },
    showCreateOperationModal() {
      this.operationObj = null
      this.createOperationModal = true
    },
    operationModalSubmit() {
      this.createOperationModal = false
      this.refreshData()
    },
    closeOperationModal() {
      this.createOperationModal = false
    },
    async getStat() {
      this.statsLoading = true
      return ApiService.getAccountStats(this.accountId, this.selectedMonth).then((response) => {
        this.stats = response.data.data
        this.statsLoading = false
      }).catch(error => { console.log(error); this.statsLoading = false })
    },
    handleRewardsChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.rewardsPagination }
      pager.current = pagination.current
      this.rewardsPagination = pager
      this.fetchRewards({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
      })
    },
    handleOperationsChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.operationsPagination }
      pager.current = pagination.current
      this.operationsPagination = pager
      this.fetchOperations({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
      })
    },
    async fetchRewards(params = {}) {
      this.rewardsLoading = true
      const reqData = { results: 10, ...params }
      return ApiService.getAccountRewards(this.accountId, this.selectedMonth, reqData).then((response) => {
        const pagination = { ...this.rewardsPagination }
        pagination.total = response.data.meta.total
        this.rewardsLoading = false
        this.rewardsData = response.data.data
        this.rewardsPagination = pagination
      }).catch(error => {
        console.log(error)
        this.rewardsLoading = false
      })
    },
    async fetchOperations(params = {}) {
      this.operationsLoading = true
      const reqData = { results: 8, ...params }
      return ApiService.getAgentAdminOperations(this.accountId, this.selectedMonth, reqData).then((response) => {
        const pagination = { ...this.operationsPagination }
        pagination.total = response.meta.total
        this.operationsLoading = false
        this.operationsData = this.formatOperationsData(response.data)
        this.operationsPagination = pagination
      }).catch(error => {
        console.log(error)
        this.operationsLoading = false
      })
    },
    async getAccounts() {
      const url = '/admin/accounts?short'
      return apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
        console.log('accounts', this.accountsData)
      }).catch(error => { console.log(error) })
    },
    formatOperationsData(data) {
      return data.map(record => {
        return {
          id: record.id,
          amount: {
            value: record.amount,
            type: record.type,
          },
          created_at: record.created_at,
        }
      })
    },
  },
}
</script>
<style lang="scss">
  .ant-small-table {
    border: 0px;
    .ant-table-column-title, .ant-table-tbody, .ant-pagination {
      font-size: 12px;
      font-weight: normal;
    }
    .ant-table-body { height: 280px; max-height: 280px; }
    .ant-table-row { border: 0px; }
    .ant-table-small {
      border: 0px;
      td, tr { border: 0px !important; }
    }
    .ant-table-placeholder {
      position: absolute;
      top: 0;
      margin-top: 3rem;
      width: 100%;
    }
  }
  .billing-stat-card {
    height: 480px;
    max-height: 480px;
  }
  .close-modal-icon {
    margin-top: -0.5rem;
    margin-right: 0rem;
  }
</style>
<style scoped>
</style>
